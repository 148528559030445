import React, { useContext, useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './heroSection.modules.css';
import { ImageWithAltText, MainDescriptionProps } from '../../../types/types';
import processMainDescriptionData from './helper';
import WinningTextComponent from './WinningText';
import DownloadButton from './DownloadButton';
import GenericSlider from '../../fields/genericSlider';
import { breakLines } from '../../../../utils/textUtils';
import PageContext from '../../../context/page/pageContext';
import { useMediaQuery } from '../../../../utils/useMedia';
import StickyBottomBar from '../stickyDownloadButton';
import StickyBottomBarV2 from '../stickyDownloadButtonV2';
import ScrollMouseIcon from "../scrollMouseIcon";

const constructImageArray = (images: Array<ImageWithAltText>): Array<React.FC> => {
	const componentArray = [];

	images?.map((image, index) => {
		componentArray.push(
			<div className='hero-section-slider-image-container'>
				<GatsbyImage image={image.imageFile} alt={image.altText} key={`hero-section-${index}`} />
			</div>
		);
	});
	return componentArray;
};
const MainDescription = (props: MainDescriptionProps) => {
	const isMobile = useMediaQuery('(max-width: 767px)');
	const isScreenSizeWeb = useMediaQuery('(min-width: 768px)');
	const [isLandscapeModeActive, setIsLandscapeModeActive] = useState(false);
	const { data, page, pageRelatedTo } = useContext(PageContext);

	const processedData = processMainDescriptionData(data, page);

	const { imageSlider, backgroundColor, smHeroSectionBanner } = processedData;
	const componentArray = constructImageArray(imageSlider?.imageArray);

	useEffect(() => {
		const landscape = window.matchMedia("(orientation: landscape)");
		if (landscape) {
			setIsLandscapeModeActive(!isLandscapeModeActive);
		}
	},[]);

	return (
		<>
			<div className='mainContainer' style={{ backgroundColor: backgroundColor }}>
				<div className='gatsby-custom-container'>
					<div className='gatsby-custom-row'>
						<div className='hero-section-left-container'>
							{processedData?.titleText?.text.toString() !=='null' && <h1
								style={{
									color: processedData?.titleText?.color,
									whiteSpace: 'pre-wrap'
								}}
								className='main-text'>
								{breakLines(processedData?.titleText?.text.toString())}
							</h1>}
							<p
								style={{
									color: processedData?.subtitleText?.color,
									whiteSpace: 'pre-wrap'
								}}
								className='sub-text hero-p-tag'>
								{breakLines(processedData?.subtitleText?.text)}
							</p>
							{processedData.downloadButtonData && processedData.winningText && (
								<div className='download-button-desktop'>
									<DownloadButton {...processedData.downloadButtonData} />
									<WinningTextComponent {...processedData.winningText} />
								</div>
							)}
							<StickyBottomBar />
							{/* {isMobile &&
								(pageRelatedTo === 'ls4page' ||
									pageRelatedTo === 'ls5page' ||
									pageRelatedTo === 'ls2page' ||
									pageRelatedTo === 'snlpage' ||
									pageRelatedTo === 'carrompage' ||
									pageRelatedTo === 'homepagehindi' ||
									pageRelatedTo === 'ls4hindipage' ||
									pageRelatedTo === 'ls4page' ||
									pageRelatedTo === 'lsbengalipage' ||
									pageRelatedTo === 'lskannadapage' ||
									pageRelatedTo === 'lstamilpage' ||
									pageRelatedTo === 'lsbhojpuripage' ||
									pageRelatedTo === 'lsgujaratipage' ||
									pageRelatedTo === 'lsmarathipage' ||
									pageRelatedTo === 'phonepepage' ||
									pageRelatedTo === 'lsremarketingpage' ||
									pageRelatedTo === 'homepage' ||
									pageRelatedTo === 'lsgoogle' ||
									pageRelatedTo === 'ls6page' ||
									pageRelatedTo === 'ls7page' ||
									pageRelatedTo === 'lsfbpage' ||
									pageRelatedTo === 'zupeenewyearcampaign' ||
									pageRelatedTo === 'tcmpage' ||
									pageRelatedTo === 'kannadanols' ||
									pageRelatedTo === 'bengalinols' ||
									pageRelatedTo === 'gujaratinols' ||
									pageRelatedTo === 'marathinols') && <StickyBottomBar />}
							{isMobile && pageRelatedTo === 'lspage' && <StickyBottomBarV2 />} */}
						</div>
						<div className='hero-section-right-container'>
							{/*	{isScreenSizeWeb && (*/}
							<div className='hero-section-slider-container'>
								<GenericSlider
									componentArray={componentArray}
									numberOfComponents={1}
									isSliderButtonEnabled={false}
									isAutoScrollEnabled={false}
									animationType={'fadeIn'}
									autoScrollTimer={imageSlider.scrollTimer}
								/>
							</div>
							{/* )} */}
							{/* {isMobile && (
								<>
									<div className='sm-screen-image-container'>
										{smHeroSectionBanner && (
											<GatsbyImage
												image={processedData?.smHeroSectionBanner.imageFile}
												alt={processedData?.smHeroSectionBanner.altText}
												className='sm-screen-image-field'
												loading='eager'
											/>
										)}
									</div>
								</>
							)} */}
						</div>
					</div>
				</div>
				{isLandscapeModeActive && <ScrollMouseIcon />}
			</div>
		</>
	);
};

export default MainDescription;
